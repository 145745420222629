<template>
  <section>
    <div class="my-3" style="padding: 0px 6rem">
      <Alert
        v-if="!canGetQuotations"
        class="mb-2 text-center py-3"
        :aling="'center'"
        type="warning"
        :message="$t('orderCreatePage.courierInformation.fillAllOrderInfo')"
      />
    </div>
    <div class="dashed-middle d-flex justify-content-center mb-3">
      <NbButton
        variant="secondary"
        :disabled="!canGetQuotations"
        style="position: relative; z-index: 1"
        @click="getQuotations()"
      >
        <NbIcon
          :icon="isLoadingQuotes ? 'loader' : 'globe'"
          :attributes="{
            class: `mr-1 mb-1 ${isLoadingQuotes ? 'spin' : ''}`,
            width: '1rem',
            height: '1rem',
          }"
        />
        {{ $t("orderCreatePage.courierInformation.getQuote") }}
      </NbButton>
    </div>
    <NbNumberedAccordionForm
      v-if="showQuotes"
      :id="'accord-form-courier'"
      :isFrom="isFrom"
      :twoPartFormDone="courierOk"
      class="mb-2"
      accordionKey="courier"
      :showAccordion="accordionAttributes.courier"
      @siwtchShowAccordion="$emit('siwtchShowAccordion', $event)"
      :number="number"
      :title="$t('orderCreatePage.courier')"
      :subTitle="$t('orderCreatePage.courierInformation.title')"
      :helpText="$t('orderCreatePage.courierInformation.helpText')"
      :hasError="sectionError"
      :errors="errors"
      :formVal.sync="courier"
    >
      <div v-if="errors && errors.contract_id" class="mb-3 text-error">
        {{ errors.contract_id[0] }}
      </div>
      <div v-if="showQuotes">
        <NbQuote
          id="contract-list"
          :genericMessage="genericMessage"
          :currency="simplifyCurrency(currency)"
          :quotes="quotes"
          @selected="quoteSelected($event)"
          :fields="fieldsQuote"
          :formVal.sync="seelectedContract"
        />
        <div class="d-flex justify-content-between mb-4">
          <div></div>
          <a href="/integrations/couriers" class="link-2" target="_blank">
            {{ $t("orderCreatePage.courierInformation.newContract") }}
          </a>
        </div>

        <div v-if="order.courier.contract_id" class="bg-background p-4 mb-4">
          <div id="volumes-list" class="mb-4" style="height: 150px">
            <div class="heading-3 mb-3">
              {{ $t("orderCreatePage.courierInformation.selectedContract") }}
            </div>
            <NbCardItemsManyAttributes
              v-if="order.courier.contract_id"
              :key="selectedQuote.id + 'quote-cards'"
              :id="selectedQuote.id + 'quote-cards-id'"
              :type="selectedQuote.section"
              :fields="fieldsQuote"
              :item="selectedQuote"
              :showRadioBtn="false"
              class="my-3"
              :formVal.sync="seelectedContractFake"
            />
          </div>

          <div
            v-for="(volume, boxIndex) in order.shipment.volumes_attributes"
            :key="boxIndex + '-volume-quotes'"
            class="card p-4 mb-3"
          >
            <div class="heading-2 mb-2">
              {{ $t("box") }}: {{ boxIndex + 1 }}
            </div>
            <div class="d-flex justify-content-between flex-wrap mt-3">
              <NbTextInput
                disabled
                :id="`volumes[${boxIndex}].freight_cost`"
                class="w-48-5 mb-4"
                type="money"
                :prepend="simplifyCurrency(currency)"
                :name="`${$t('freightCost')} (${simplifyCurrency(currency)})`"
                :helpText="
                  $t('orderCreatePage.courierInformation.freightcostHelpText', {
                    val: $helpers.getPlatform().toUpperCase(),
                  })
                "
                :decimals="2"
                :min="0.0"
                :error="errors[`volumes[${boxIndex}].freight_cost`]"
                v-model="volume.freight_cost"
              />
              <NbTextInput
                required
                :id="`volumes[${boxIndex}].freight_value`"
                class="w-48-5 mb-4"
                type="money"
                :prepend="simplifyCurrency(currency)"
                :name="`${$t('freightValue')} (${simplifyCurrency(currency)})`"
                :helpText="
                  $t('orderCreatePage.courierInformation.freightvalueHelpText')
                "
                :decimals="2"
                :min="0.0"
                :error="errors[`volumes[${boxIndex}].freight_value`]"
                @valid="$emit('updateValidFields', $event)"
                @invalid="$emit('updateInvalidFields', $event)"
                @blur="
                  getSimpleQuote(
                    boxIndex,
                    volume.freight_value,
                    'freight_details'
                  )
                "
                v-model="volume.freight_value"
              />
            </div>
            <div
              v-if="selectedContract && selectedContract.is_auto_tax_defined"
              class="d-flex justify-content-between flex-wrap"
            >
              <NbTextInput
                required
                :id="`volumes[${boxIndex}].duties_tax_value`"
                class="mt-3 w-48-5"
                type="money"
                :prepend="simplifyCurrency(currency)"
                :name="`${$t('dutiesTaxValue')} (${simplifyCurrency(
                  currency
                )})`"
                :helpText="
                  $t('orderCreatePage.courierInformation.dutiestaxHelpText')
                "
                :decimals="2"
                :min="0.0"
                :error="errors[`volumes[${boxIndex}].duties_tax_value`]"
                @valid="$emit('updateValidFields', $event)"
                @invalid="$emit('updateInvalidFields', $event)"
                @blur="
                  getSimpleQuote(
                    boxIndex,
                    volume.duties_tax_value,
                    'duties_tax_details'
                  )
                "
                @input="testCourierInformations()"
                v-model="volume.duties_tax_value"
              />
              <NbTextInput
                required
                :id="`volumes[${boxIndex}].sales_tax_value`"
                class="mt-3 w-48-5"
                type="money"
                :prepend="simplifyCurrency(currency)"
                :name="`${$t('salesTaxValue')} (${simplifyCurrency(currency)})`"
                :helpText="
                  $t('orderCreatePage.courierInformation.salestaxHelpText')
                "
                :decimals="2"
                :min="0.0"
                :error="errors[`volumes[${boxIndex}].sales_tax_value`]"
                @valid="$emit('updateValidFields', $event)"
                @invalid="$emit('updateInvalidFields', $event)"
                @blur="
                  getSimpleQuote(
                    boxIndex,
                    volume.sales_tax_value,
                    'sales_tax_details'
                  )
                "
                @input="testCourierInformations()"
                v-model="volume.sales_tax_value"
              />
            </div>
            <div
              v-if="courierId"
              class="d-flex justify-content-between flex-wrap"
            >
              <NbTextInput
                required
                :name="`${$t('lastMileTrackingNumber')}`"
                :helpText="
                  $t(
                    'orderCreatePage.courierInformation.lastmilenumberHelpText'
                  )
                "
                class="mt-3 w-100"
                :placeholder="
                  $t(
                    'orderCreatePage.courierInformation.lastMileNumberPlaceholder'
                  )
                "
                :id="`volumes[${boxIndex}].last_mile_number`"
                :error="errors[`volumes[${boxIndex}].last_mile_number`]"
                @valid="$emit('updateValidFields', $event)"
                @invalid="$emit('updateInvalidFields', $event)"
                @input="testCourierInformations()"
                @blur="validateAcceptance()"
                v-model="volume.last_mile_number"
              />
              <!--
              <div class="text-primary">
                <strong> {{ $t('orderCreatePage.shipmentInformation.landedCostWarning') }}</strong>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>
    </NbNumberedAccordionForm>
  </section>
</template>

<script>
import NbNumberedAccordionForm from "@/components/forms/NbNumberedAccordionForm.vue";
import NbQuote from "../../../../components/quote/NbQuote.vue";
import NbCardItemsManyAttributes from "@/components/cards/NbCardItemsManyAttributes.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import Alert from "@/components/alerts/Alert.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
//services
import QuoteService from "../../../../services/QuoteService";
import ContractService from "@/services/ContractService";
const quoteService = new QuoteService();
const contractService = new ContractService();

import NProgress from "nprogress";

export default {
  components: {
    NbNumberedAccordionForm,
    NbQuote,
    NbCardItemsManyAttributes,
    NbButton,
    NbTextInput,
    Alert,
    NbIcon,
  },
  props: {
    /* value: {
      type: Object,
      required: true,
    }, */
    formVal: {
      type: Object,
      required: true,
    },
    isFrom: {
      type: String,
      required: false,
    },
    currency: {
      type: String,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    triggerResetCourier: {
      type: Boolean,
      required: false,
    },
    number: {
      type: Number,
      required: true,
    },
    allSteps: {
      type: Array,
      required: true,
    },
    progressValue: {
      type: [Number, String],
      required: false,
    },
    errors: {
      type: Object,
      required: true,
    },
    sectionError: {
      type: Boolean,
      required: false,
    },
    accordionAttributes: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      quotes: {
        success: [],
        partial_success: [],
        error: [],
      },
      seelectedContract: "",
      selectedContract: {},
      seelectedContractFake: "",
      selectedQuote: {},
      contracts: [],
      errorQuoteCreate: false,
      courierOk: false,
      showQuotes: false,
      genericMessage:
        "Please change the value of the items. You can also decide to put another value for the freight, down this page.",
      courierId: null,
      isLoadingQuotes: false,
      timer: null,
    };
  },
  created() {},
  methods: {
    resetQuotes() {
      this.quotes = {
        success: [],
        partial_success: [],
        error: [],
      };
      this.showQuotes = false;
    },
    loadContracts() {
      if (
        this.order.shipment.incoterm &&
        this.order.shipment.total_value &&
        this.order.receiver.customer_postal_code.length > 5
      ) {
        this.contracts = [];
        contractService
          .getContractsByCep(
            this.order.receiver.customer_postal_code || -1,
            this.order.shipment.incoterm,
            this.order.shipment.total_value,
            this.order.shipment.currency || this.currency
          )
          .then((response) => {
            this.contracts = response.data.data;
          });
      }
    },
    validateAcceptance() {
      if (!this.order.courier.contract_id) {
        this.acceptance = false;
        return;
      }

      if (this.selectedQuote.section != "success") {
        this.acceptance = false;
        return;
      }

      if (this.courierId) {
        const volumesLength = this.order.shipment.volumes_attributes.length;
        let count = 0;

        this.order.shipment.volumes_attributes.forEach((volume) => {
          if (volume.last_mile_number) {
            count++;
          }
        });

        if (count != volumesLength) {
          this.acceptance = false;
          return;
        }
      }

      this.acceptance = true;
    },
    checkLandedInconterm() {
      const incoterm = this.order.shipment.incoterm;
      const is_landed_cost = this.order.shipment.is_landed_cost;

      if (incoterm === "DDU") {
        this.order.shipment.is_landed_cost = null;
        return true;
      }

      if (typeof is_landed_cost !== "boolean" && incoterm === "DDP") {
        return false;
      }

      return true;
    },
    checkAllSteps() {
      let count = 0;
      this.computedAllSteps.forEach((step) => {
        if (step.formDone) count++;
      });
      if (count >= this.computedAllSteps.length) {
        return true;
      }
      return false;
    },

    getQuotations() {
      this.isLoadingQuotes = true;
      this.loadContracts();
      let dataToSend = this.buildQuoteToSend();

      /*
      dataToSend.volumes_attributes.forEach(volume => {
        delete volume.duties_tax_value;
        delete volume.sales_tax_value;
        delete volume.freight_cost;
        delete volume.freight_value;
      });
      */

      this.resetCourier();
      quoteService
        .getQuotation(dataToSend)
        .then((response) => {
          this.isLoadingQuotes = false;
          if (
            response.data.data.success.length > 0 ||
            response.data.data.partial_success.length > 0 ||
            response.data.data.error.length > 0
          ) {
            this.errorQuoteCreate = "";

            this.quotes.success = response.data.data.success.map((obj) => ({
              ...obj,
              name: obj.contract_name,
              id: obj.contract_id,
              section: "success",
              freight_details_cost: JSON.parse(
                JSON.stringify(obj.freight_details)
              ),
              duties_tax_details: Array.from(
                { length: obj.freight_details.length },
                () => 0.0
              ),
              sales_tax_details: Array.from(
                { length: obj.freight_details.length },
                () => 0.0
              ),
            }));
            this.quotes.partial_success =
              response.data.data.partial_success.map((obj) => ({
                ...obj,
                name: obj.contract_name,
                id: obj.contract_id,
                section: "partial_success",
                freight_details_cost: JSON.parse(
                  JSON.stringify(obj.freight_details)
                ),
                duties_tax_details: Array.from(
                  { length: obj.freight_details.length },
                  () => 0.0
                ),
                sales_tax_details: Array.from(
                  { length: obj.freight_details.length },
                  () => 0.0
                ),
              }));
            this.quotes.error = response.data.data.error.map((obj) => ({
              ...obj,
              name: obj.contract_name,
              id: obj.contract_id,
              section: "error",
            }));

            this.showQuotes = true;
            this.$emit("siwtchShowAccordion", "courier");
            //check if order has id
            if (this.order.courier.contract_id) {
              this.seelectedContract = this.order.courier.contract_id;
              this.selectedQuote = this.findQuoteById(this.seelectedContract);
            }
          } else {
            this.errorQuoteCreate = "No contracts were found for this quote";
          }
          setTimeout(() => {
            this.scrollToId("courier-information");
          }, 300);
          NProgress.done();
        })
        .catch(() => {
          this.isLoadingQuotes = false;
          NProgress.done();
        });
    },
    buildQuoteToSend(isError) {
      const formData = { ...this.order };
      let quoteData = JSON.parse(JSON.stringify(formData));

      let builded = {
        customer_postal_code: quoteData.receiver.customer_postal_code,
        customer_country: quoteData.receiver.customer_country,
        seller_country: quoteData.receiver.seller_country,
        customer_state: quoteData.receiver.customer_state,
        incoterm: quoteData.shipment.incoterm,
        is_landed_cost: quoteData.shipment.is_landed_cost,
        currency: quoteData.shipment.currency,
      };

      builded.volumes_attributes = this.calculateVolumesWeight(
        quoteData.shipment.volumes_attributes,
        isError
      );

      builded.volumes_attributes.forEach((element) => {
        element.weight = parseFloat(element.weight);
        element.width = parseFloat(element.width);
        element.height = parseFloat(element.height);
        element.length = parseFloat(element.length);
      });
      return builded;
    },
    resetCourier() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.seelectedContract = "";
        this.order.courier.contract_id = "";
        this.order.shipment.volumes_attributes.forEach((volume) => {
          volume.freight_cost = null;
          volume.freight_value = null;
        });
        this.resetQuotes();
        this.testCourierInformations();
      }, 300);
    },
    quoteSelected(event) {
      this.$emit("resetCourierErrors");
      setTimeout(() => {
        this.scrollToId("volumes-list");
      }, 500);
      if (event.contract_id) {
        this.courier.contract_id = JSON.parse(
          JSON.stringify(event.contract_id)
        );
        this.order.shipment.freight_cost = event.freight_cost;
        this.getCourier();
        this.order.shipment.volumes_attributes.forEach((volume, index) => {
          volume.freight_cost = Number(
            event.freight_details_cost[index]
          ).toFixed(2);
          volume.freight_value = Number(event.freight_details[index]).toFixed(
            2
          );
          volume.duties_tax_value = Number(
            event.duties_tax_details[index]
          ).toFixed(2);
          volume.sales_tax_value = Number(
            event.sales_tax_details[index]
          ).toFixed(2);
        });
        return;
      }
      this.order.shipment.volumes_attributes.forEach((volume) => {
        volume.freight_cost = Number(0.0).toFixed(2);
        volume.freight_value = Number(0.0).toFixed(2);
      });
      this.courier.contract_id = null;
    },
    getCourier() {
      contractService.getContract(this.courier.contract_id).then(({ data }) => {
        this.courierId = data.data.is_only_tracking;

        if (!this.courierId) {
          this.order.shipment.volumes_attributes.forEach((volume) => {
            delete volume.last_mile_number;
          });
          this.testCourierInformations();
          return;
        }

        this.order.shipment.volumes_attributes.forEach((volume) => {
          volume.last_mile_number = "";
        });
        this.testCourierInformations();
      });
    },
    getSimpleQuote(boxIndex, newFreighValue, attribute) {
      let quoteFromQuotes = this.findQuoteById(this.seelectedContract);
      quoteFromQuotes[attribute][boxIndex] = newFreighValue;

      const dataToSend = {};
      const freightCalcs = this.getFreightCalcs();

      dataToSend.customer_postal_code =
        this.order.receiver.customer_postal_code;
      dataToSend.customer_state = this.order.receiver.customer_state;
      dataToSend.incoterm = this.order.shipment.incoterm;
      dataToSend.contract_id = this.order.courier.contract_id;
      dataToSend.freight_cost = freightCalcs.freight_cost;
      dataToSend.freight_value_details = freightCalcs.freight_value_details;
      dataToSend.is_landed_cost = this.order.shipment.is_landed_cost;
      dataToSend.currency = this.order.shipment.currency;
      dataToSend.volumes_attributes = this.order.shipment.volumes_attributes;

      if (!this.order.courier.contract_id) {
        this.$helpers.toast(
          "Select an contract and set this input again.",
          "danger",
          2000
        );
        return;
      }

      this.$emit("siwtchShowAccordion", "courier");
      quoteService
        .getQuotationSimple(dataToSend)
        .then((response) => {
          this.showQuotes = true;
          //find contract and update it
          let finalQuote = this.findQuoteById(this.seelectedContract);

          if (response.data.data.quote_status) {
            typeof response.data.data.duties_tax === "string" ||
            typeof response.data.data.duties_tax === "number"
              ? (finalQuote.duties_tax = response.data.data.duties_tax)
              : (finalQuote.duties_tax = null);
            typeof response.data.data.sales_tax === "string" ||
            typeof response.data.data.sales_tax === "number"
              ? (finalQuote.sales_tax = response.data.data.sales_tax)
              : (finalQuote.sales_tax = null);
            typeof response.data.data.total_cost === "string" ||
            typeof response.data.data.total_cost === "number"
              ? (finalQuote.total_cost = response.data.data.total_cost)
              : (finalQuote.total_cost = null);
            response.data.data.quote_status.toLowerCase() === "success"
              ? (finalQuote.message = "")
              : (finalQuote.message = response.data.data.message);
          }
          if (
            response.data.data.quote_status.toLowerCase() ===
            finalQuote.section.toLowerCase()
          ) {
            //**** critico!!! adiciona o novo card no status referente e remove o card velho
            this.quotes[response.data.data.quote_status.toLowerCase()].splice(
              finalQuote.index,
              0,
              finalQuote
            );
            this.quotes[finalQuote.section].splice(finalQuote.index + 1, 1);
          } else {
            //**** critico!!! remove o card velho no status referente, atualiza o status do novo card e adiciona no status referente
            this.quotes[finalQuote.section].splice(finalQuote.index, 1);
            finalQuote.section = response.data.data.quote_status.toLowerCase();
            this.quotes[response.data.data.quote_status.toLowerCase()].push(
              finalQuote
            );
          }

          this.selectedQuote = finalQuote;
        })
        .catch((err) => {
          this.showQuotes = true;
          let errs = this.$helpers.extractErrorMessages(err);
          this.errorQuoteCreate = errs[0];
        });
    },

    getFreightCalcs() {
      const finalData = { freight_cost: 0, freight_value_details: [] };

      this.order.shipment.volumes_attributes.forEach((volume) => {
        finalData.freight_cost += Number(volume.freight_cost);
        finalData.freight_value_details.push(Number(volume.freight_value));
      });
      return finalData;
    },

    findQuoteById(id) {
      let contractFound = false;
      let finalContract = {};
      Object.keys(this.quotes).forEach((key) => {
        if (contractFound) return;

        this.quotes[key].forEach((contract, index) => {
          if (contractFound) return;

          if (contract.id === id) {
            contract.index = index;
            contractFound = true;
            finalContract = contract;
          }
        });
      });
      return finalContract;
    },

    calculateVolumesWeight(volumes, err) {
      if (err) {
        return volumes.map((volume) => {
          volume.weight = volume.empty_weight;
          return volume;
        });
      } else {
        return volumes.map((volume) => {
          volume.empty_weight = volume.weight; //bug fix with total weight

          let total = parseFloat(volume.empty_weight || 0);
          if (volume.order_items_attributes) {
            volume.order_items_attributes.forEach((product) => {
              total +=
                parseFloat(product.weight || 0) *
                parseFloat(product.quantity || 0);
            });
          }
          volume.weight = total;
          return volume;
        });
      }
    },
    simplifyCurrency(currency) {
      if (currency === "USD") {
        return "$";
      } else {
        return "R$";
      }
    },
    scrollToId(id) {
      if (id) {
        const elmnt = document.getElementById(id);
        elmnt.scrollIntoView({ behavior: "smooth" });
      }
    },
    testCourierInformations() {
      if (this.order.courier.contract_id) {
        this.courierOk = true;
        let testList = [];
        if (
          this.selectedContract &&
          this.selectedContract.is_auto_tax_defined
        ) {
          testList.push("duties_tax_value", "sales_tax_value");
        }
        if (this.courierId) {
          testList.push("last_mile_number");
        }

        if (testList.length > 0) {
          this.order.shipment.volumes_attributes.forEach((volume) => {
            this.courierOk = testList.every((key) => {
              const value = volume[key];
              return value !== undefined && value !== null && value !== "";
            });
          });
        }
      } else {
        this.courierOk = false;
      }

      this.sendFormDoneChange();
    },
    sendFormDoneChange() {
      //Time out waiting for all functions and values are ready
      setTimeout(() => {
        this.$emit("formDoneChangeCourier", this.courierOk);
        this.validateAcceptance();
      }, 200);
    },
  },
  computed: {
    courier: {
      get() {
        return this.formVal;
      },
      set(val) {
        this.$emit("update:formVal", val);
      },
    },
    canGetQuotations() {
      /* const resultCheckLandedInconterm = this.checkLandedInconterm(); */
      if (this.isLoadingQuotes) {
        return false;
      }
      const resultCheckAllSteps = this.checkAllSteps();
      if (
        /* resultCheckLandedInconterm &&  */
        resultCheckAllSteps
      ) {
        return true;
      }
      return false;
    },
    fieldsQuote() {
      return [
        {
          key: "freight_cost",
          label: this.$t("freightCost"),
          helpText: this.$t(
            "orderCreatePage.courierInformation.quoteHelpTexts.freightCost",
            { val: this.$helpers.getPlatform() }
          ),
          prepend: this.simplifyCurrency(this.currency),
          isCurrency: true,
        },
        {
          key: "duties_tax",
          label: this.$t("dutiesTax"),
          helpText: this.$t(
            "orderCreatePage.courierInformation.quoteHelpTexts.dutiesTax"
          ),
          prepend: this.simplifyCurrency(this.currency),
          isCurrency: true,
        },
        {
          key: "sales_tax",
          label: this.$t("salesTax"),
          helpText: this.$t(
            "orderCreatePage.courierInformation.quoteHelpTexts.salesTax"
          ),
          prepend: this.simplifyCurrency(this.currency),
          isCurrency: true,
        },
        {
          key: "total_cost",
          label: this.$t("totalCost"),
          helpText: this.$t(
            "orderCreatePage.courierInformation.quoteHelpTexts.totalCost"
          ),
          prepend: this.simplifyCurrency(this.currency),
          isCurrency: true,
        },
        {
          key: "delivery_time",
          label: this.$t("deliveryTime"),
          helpText: this.$t(
            "orderCreatePage.courierInformation.quoteHelpTexts.deliveryTime"
          ),
          postpend: this.$t("trackMyOrderViewPage.days"),
        },
        /* { key: "id", label: 'ID' }, */
      ];
    },
    computedAllSteps() {
      let finalResult = [...this.allSteps];
      finalResult.pop();
      return finalResult;
    },
  },
  watch: {
    formVal() {
      let newProducts = [];
      this.formVal.courier.volumes_attributes.forEach((volume) => {
        newProducts = [...newProducts, ...volume.order_items_attributes];
      });
      if (newProducts.length) this.products = newProducts;
    },
    seelectedContract(newVal) {
      /* GAMBIARRA TODO: verificar a substituiçao do seelectedContract por selectedContract */
      if (newVal) {
        const contractSelected = this.contracts.find(
          (contract) => contract.id === newVal
        );
        this.selectedContract = contractSelected;
        this.selectedQuote = this.findQuoteById(newVal);
        this.courier.selectedQuote = { ...this.selectedQuote };
        this.$emit("updateValidFields", "contract_id");
        return;
      }
    },
    triggerResetCourier() {
      this.resetCourier();
    },
    "order.courier.contract_id": function () {
      this.testCourierInformations();
    },

    //reset courier watchers
    "order.receiver.customer_postal_code": function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.resetCourier();
      }
    },
    "order.receiver.customer_state": function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.resetCourier();
      }
    },
    "order.receiver.customer_country": function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.resetCourier();
      }
    },
    "order.shipment.incoterm": function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.resetCourier();
      }
    },
    "order.shipment.is_landed_cost": function (newVal, oldVal) {
      if (newVal != oldVal && this.order.shipment.incoterm === "DDP") {
        this.resetCourier();
      }
    },
    "order.shipment.currency": function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.resetCourier();
      }
    },
  },
};
</script>

<style scoped>
.dashed-middle {
  width: 100%;
  position: relative;
}

.dashed-middle:before {
  content: "";
  position: absolute;
  bottom: 50%;
  border-bottom: 1px var(--gray-20) dashed;
  width: 100%;
  z-index: 0;
}
</style>
