import { render, staticRenderFns } from "./UnsavedWorkModal.vue?vue&type=template&id=b4d3e32e"
import script from "./UnsavedWorkModal.vue?vue&type=script&lang=js"
export * from "./UnsavedWorkModal.vue?vue&type=script&lang=js"
import style0 from "./UnsavedWorkModal.vue?vue&type=style&index=0&id=b4d3e32e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports