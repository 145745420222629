<template>
  <section>
    <NbNumberedAccordionForm
      :id="'accord-form-receiver'"
      class="mb-2"
      accordionKey="receiver"
      :showAccordion="accordionAttributes.receiver"
      @siwtchShowAccordion="$emit('siwtchShowAccordion', $event)"
      :number="number"
      :title="$t('orderCreatePage.receiver')"
      :subTitle="$t('orderCreatePage.receiverInformation.title')"
      :helpText="$t('orderCreatePage.receiverInformation.helpText')"
      :hasError="sectionError.receiver"
      :errors="errors"
      :formInputs="receiverInputs"
      @updateValidFields="$emit('updateValidFields', $event)"
      @invalid="$emit('updateInvalidFields', $event)"
      @formDoneChange="$emit('formDoneChangeReceiver', $event)"
      :formVal.sync="receiver"
    >
    </NbNumberedAccordionForm>

    <section
      v-if="this.currentSeller.is_multiple"
      id="sender-information"
      class="mt-1"
    >
      <NbNumberedAccordionForm
        :id="'accord-form-seller'"
        class="mb-2"
        accordionKey="seller"
        :showAccordion="accordionAttributes.seller"
        @siwtchShowAccordion="$emit('siwtchShowAccordion', $event)"
        :number="getStepIndex('sender-information') + 1"
        :title="$t('orderCreatePage.sender')"
        :subTitle="$t('orderCreatePage.originInformation.title')"
        :helpText="$t('orderCreatePage.originInformation.helpText')"
        :hasError="sectionError.seller"
        :errors="errors"
        :formInputs="sellerInputs"
        @updateValidFields="$emit('updateValidFields', $event)"
        @invalid="$emit('updateInvalidFields', $event)"
        @formDoneChange="$emit('formDoneChangeSender', $event)"
        :formVal.sync="receiver"
      >
        <template #beforeForm>
          <NbButton
            class="float-right mr-4"
            variant="tertiary"
            icon="fas fa-paste"
            @click="updateCompanyInfo()"
          >
            {{ $t("orderCreatePage.originInformation.buttonCompanyInfo") }}
          </NbButton>
        </template>
      </NbNumberedAccordionForm>
    </section>
  </section>
</template>

<script>
import { getValidDocumentName } from "@/validators.js";
import {
  STATES,
  COUNTRIES_REGEX,
  COUNTRIES_RECEIVER_REGEX,
} from "@/constants.js";
import COUNTRIES from "countries-phone-masks";
import NbNumberedAccordionForm from "@/components/forms/NbNumberedAccordionForm.vue";
import NbButton from "@/components/buttons/NbButton.vue";
/* import NProgress from "nprogress"; */

/* import CustomerService from "@/services/CustomerService.js"; */
import CountryService from "@/services/CountryService";

/* const customerService = new CustomerService(); */
const countryService = new CountryService();

export default {
  name: "ReceiverInformation",
  components: {
    NbNumberedAccordionForm,
    NbButton,
  },
  props: {
    /* value: {
      type: Object,
      required: true,
    }, */
    formVal: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    sectionError: {
      type: Object,
      required: false,
    },
    currentSeller: {
      type: Object,
      required: true,
    },
    allSteps: {
      type: Array,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
    accordionAttributes: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      /* receiver: this.value, */
      countries: [
        { value: "AR", text: "Argentina" },
        { value: "BR", text: "Brazil" },
        { value: "CL", text: "Chile" },
        { value: "CO", text: "Colombia" },
        { value: "EC", text: "Ecuador" },
        { value: "MX", text: "Mexico" },
        { value: "PE", text: "Peru" },
        { value: "UY", text: "Uruguay" },
      ],
      countriesAll: [],
      receiverCountryConfigurations: {
        countryName: "Brazil",
        countryAlpha2Code: "BR",
        documentName: "CPF/CNPJ",
        documentNameHelpText:
          "Cadastro de Pessoas Físicas (CPF) Cadastro Nacional da Pessoa Jurídica (CNPJ)",
        documentFormat: "123.456.789-01",
        cepFormat: "12345-678",
        mask: ["#####-###"],
        cepLength: "8",
        phoneLength: "0",
        documentMasks: ["###.###.###-##", "##.###.###/####-##"],
      },
      sellerCountryConfigurations: {
        phoneLength: "6",
        cepLength: "0",
      },
      documentPlaceholder: {
        Brazil: "123.456.789-12",
        Mexico: "ABCD123456EFGHI78",
      },
      countriesPhoneMask: COUNTRIES,
      confirmOptions: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
    };
  },
  created() {
    this.getAllCountries();
  },
  methods: {
    /* saveContact() {
      const contactData = {};

      Object.keys(this.receiver).forEach((key) => {
        const transposedKey = key.split("customer_")[1];
        contactData[transposedKey] = this.receiver[key];
      });
      customerService
        .createCustomer({ customer: contactData })
        .finally(NProgress.done);
    }, */

    updateCompanyInfo() {
      //sellerService.getCurrentSeller().then((response) => {
      //this.currentSeller = response.data.data;
      if (this.currentSeller.name)
        this.receiver.seller_name = this.currentSeller.name;
      if (this.currentSeller.address)
        this.receiver.seller_address = this.currentSeller.address;
      if (this.currentSeller.address_number)
        this.receiver.seller_address_number = this.currentSeller.address_number;
      if (this.currentSeller.address_complement)
        this.receiver.seller_address_complement =
          this.currentSeller.address_complement;
      if (this.currentSeller.city)
        this.receiver.seller_city = this.currentSeller.city;
      if (this.currentSeller.state)
        this.receiver.seller_state = this.currentSeller.state;
      if (this.currentSeller.country)
        this.receiver.seller_country = this.currentSeller.country;
      if (this.currentSeller.email)
        this.receiver.seller_email = this.currentSeller.email;
      if (this.currentSeller.zip_code)
        this.receiver.seller_zip_code = this.currentSeller.zip_code;
      if (this.currentSeller.tax_number)
        this.receiver.seller_tax_number = this.currentSeller.tax_number;
      if (this.currentSeller.remetente_is_cpf)
        this.receiver.remetente_is_cpf = this.currentSeller.remetente_is_cpf;
      if (this.currentSeller.site)
        this.receiver.seller_website = this.currentSeller.site;
      if (this.currentSeller.phone) {
        this.receiver.seller_phone = this.currentSeller.phone;
        this.$emit("fixSellerPhone");
      }
      //});
    },
    getAllCountries() {
      countryService.getCountries().then((response) => {
        this.countriesAll = response.data.data.map(({ alpha2_code, name }) => ({
          value: alpha2_code,
          text: name,
        }));
      });
    },
    getStepIndex(str) {
      return this.allSteps.findIndex((step) => step.link === str);
    },
  },

  computed: {
    receiver: {
      get() {
        return this.formVal;
      },
      set(val) {
        this.$emit("update:formVal", val);
      },
    },
    receiverInputs() {
      return [
        {
          required: true,
          id: "customer_full_name",
          type: "NbCustomerTypeAhead",
          name: this.$t("orderCreatePage.receiverInformation.receiverName"),
          classes: "mb-3 w-66",
          placeholder: this.$t("fullName"),
          key: "customer_full_name",
        },
        {
          required: true,
          id: "customer_country",
          type: "select",
          options: this.countries,
          name: this.$t("country"),
          classes: "mb-3 w-32-5",
          placeholder: this.$t("country"),
          key: "customer_country",
        },
        {
          required: true,
          id: "customer_phone",
          type: "international-phone",
          rules: `length:${
            this.receiverCountryConfigurations.phoneLength || "0"
          }`,
          name: this.$t("phone"),
          disableOn: this.receiverCountryConfigurations.countryAlpha2Code,
          classes: "mb-3 w-32-5",
          placeholder: "(61)12345-6789",
          key: "customer_phone",
          codeKey: "customer_phone_country_code",
          helpText: this.$t(
            "orderCreatePage.receiverInformation.customerPhoneHelpText",
            { country: this.receiverCountryConfigurations.countryName }
          ),
        },
        {
          required: true,
          id: "customer_email",
          type: "email",
          name: this.$t("email"),
          classes: "mb-3 w-32-5",
          placeholder: this.$t("placeholderEmail"),
          key: "customer_email",
        },
        {
          required: true,
          id: "customer_document_number",
          name: `${this.$t("trackingNumber")} (${
            this.receiverCountryConfigurations.documentName
          })`,
          country: this.receiver.customer_country,
          masks: this.receiverCountryConfigurations.documentMasks,
          classes: "mb-3 w-32-5",
          placeholder: this.receiverCountryConfigurations.documentFormat,
          key: "customer_document_number",
          helpText: this.$t(
            "orderCreatePage.receiverInformation.cpfOrCnpjHelpText",
            {
              country: this.receiverCountryConfigurations.countryName,
              documentName: this.receiverCountryConfigurations.documentName,
              documentFormat: this.receiverCountryConfigurations.documentFormat,
            }
          ),
          error: this.errors.customer_document_type,
        },
        {
          required: true,
          id: "customer_postal_code",
          type:
            this.receiverCountryConfigurations.countryAlpha2Code === "BR"
              ? "postalCode"
              : "",
          rules: `length:${
            this.receiverCountryConfigurations.cepLength || "0"
          }`,
          name: this.$t("postalCode"),
          masks: this.receiverCountryConfigurations.mask,
          classes: "mb-3 w-32-5",
          placeholder: this.receiverCountryConfigurations.cepFormat,
          key: "customer_postal_code",
          helpText: this.$t(
            "orderCreatePage.receiverInformation.postalCodeHelpText",
            {
              country: this.receiverCountryConfigurations.countryName,
              cepFormat: this.receiverCountryConfigurations.cepFormat,
            }
          ),
        },
        {
          required: true,
          id: "customer_address",
          name: this.$t("address"),
          classes: "mb-3 w-32-5",
          placeholder: this.$t(
            "orderCreatePage.receiverInformation.customeraddressPlaceholder"
          ),
          key: "customer_address",
          helpText: this.$t(
            "orderCreatePage.receiverInformation.customeraddressHelpText"
          ),
        },
        {
          required: false,
          type: "number",
          id: "customer_address_number",
          name: this.$t("addressNumber"),
          classes: "mb-3 w-32-5",
          placeholder: this.$t(
            "orderCreatePage.receiverInformation.customeraddressnumberPlaceholder"
          ),
          key: "customer_address_number",
          helpText: this.$t(
            "orderCreatePage.receiverInformation.customeraddressnumberHelpText"
          ),
        },
        {
          required: false,
          id: "customer_address_complement",
          name: this.$t("complement"),
          classes: "mb-3 w-32-5",
          placeholder: this.$t(
            "orderCreatePage.receiverInformation.customeraddresscopmlementPlaceholder"
          ),
          key: "customer_address_complement",
          helpText: this.$t(
            "orderCreatePage.receiverInformation.customeraddresscomplementHelpText"
          ),
        },
        {
          required: false,
          id: "customer_address_reference",
          name: this.$t("customersPage.addressReference"),
          classes: "mb-3 w-66",
          placeholder: this.$t(
            "orderCreatePage.receiverInformation.customeraddressreferencePlaceholder"
          ),
          key: "customer_address_reference",
          helpText: this.$t(
            "orderCreatePage.receiverInformation.customeraddressreferenceHelpText"
          ),
        },
        {
          required: true,
          id: "customer_city",
          name: this.$t("city"),
          classes: "mb-3 w-49-5",
          placeholder: "",
          key: "customer_city",
        },
        {
          required: true,
          id: "customer_state",
          type: "select",
          options: this.statesOptions,
          name: this.$t("state"),
          classes: "mb-3 w-49-5",
          placeholder: "",
          key: "customer_state",
        },
        {
          required: false,
          id: "is_commercial_destination",
          type: "checkbox",
          name: this.$t("isCommercialDestinationMandatory"),
          classes: "mb-3 w-100",
          key: "is_commercial_destination",
          error: this.errors.is_commercial_destination,
        },
        {
          id: "seller_rfb_code",
          options: this.countries,
          name: this.$t("sellerRfbCode"),
          classes: "mb-3  w-49-5",
          key: "seller_rfb_code",
          helpText: this.$t(
            "orderCreatePage.receiverInformation.rfbCodeHelpText"
          ),
        },
        {
          required: false,
          id: "seller_legal_name",
          name: this.$t("sellerLegalName"),
          classes: "mb-3 w-49-5",
          placeholder: this.$t("name"),
          key: "seller_legal_name",
        },
      ];
    },
    sellerInputs() {
      return [
        {
          required: true,
          id: "seller_name",
          name: this.$t("sellerName"),
          classes: "mb-3 w-66",
          key: "seller_name",
        },
        {
          required: true,
          id: "seller_country",
          type: "select",
          options: this.countriesAll,
          name: this.$t("sellerCountry"),
          classes: "mb-3 w-32-5",
          placeholder: this.$t("country"),
          key: "seller_country",
        },
        {
          required: false,
          id: "remetente_is_cpf",
          type: "radio",
          options: this.confirmOptions,
          classes: "mb-3 w-32-5",
          inputClasses: "display-block",
          key: "remetente_is_cpf",
          helpText: this.$t(
            "orderCreatePage.receiverInformation.remetenteiscpfHelptext"
          ),
          name: `${this.$t("recommendedCnpj")}`,
        },
        {
          required: true,
          id: "seller_tax_number",
          name: this.$t("sellerTaxNumber"),
          classes: "mb-3 w-32-5",
          placeholder: this.$t(
            "orderCreatePage.receiverInformation.sellertaxnumberPlaceholder"
          ),
          key: "seller_tax_number",
          helpText: this.$t(
            "orderCreatePage.receiverInformation.sellertaxnumberHelpText"
          ),
        },
        {
          required: true,
          id: "seller_phone",
          type: "international-phone",
          rules: `length:6`,
          /* rules: `length:${
            this.sellerCountryConfigurations.phoneLength || "6"
          }`, */
          name: this.$t("sellerPhone"),
          classes: "mb-3 w-32-5",
          placeholder: this.$t(
            "orderCreatePage.receiverInformation.sellerphonePlaceholder"
          ),
          key: "seller_phone_number",
          codeKey: "seller_phone_code",
          helpText: this.$t(
            "orderCreatePage.receiverInformation.sellerphoneHelpText"
          ),
          error: this.errors["seller_phone"],
        },
        {
          required: true,
          id: "seller_email",
          type: "email",
          name: this.$t("sellerEmail"),
          classes: "mb-3 w-32-5",
          placeholder: this.$t("placeholderEmail"),
          key: "seller_email",
        },
        {
          required: true,
          id: "seller_zip_code",
          rules: `length:${this.sellerCountryConfigurations.cepLength || "0"}`,
          name: this.$t("sellerZipCode"),
          classes: "mb-3 w-32-5",
          placeholder: this.$t(
            "orderCreatePage.receiverInformation.sellerpostalcodePlaceholder"
          ),
          key: "seller_zip_code",
        },
        {
          required: true,
          id: "seller_address",
          name: this.$t("sellerAddress"),
          classes: "mb-3 w-32-5",
          placeholder: this.$t(
            "orderCreatePage.receiverInformation.selleraddressPlaceholder"
          ),
          key: "seller_address",
          helpText: this.$t(
            "orderCreatePage.receiverInformation.selleraddressHelpText"
          ),
        },
        {
          required: true,
          type: "number",
          id: "seller_address_number",
          name: this.$t("sellerAddressNumber"),
          classes: "mb-3 w-32-5",
          placeholder: this.$t(
            "orderCreatePage.receiverInformation.selleraddressnumberPlaceholder"
          ),
          key: "seller_address_number",
          helpText: this.$t(
            "orderCreatePage.receiverInformation.selleraddressnumberHelpText"
          ),
        },
        {
          required: false,
          id: "seller_address_complement",
          name: this.$t("sellerAddressComplement"),
          classes: "mb-3 w-32-5",
          placeholder: this.$t(
            "orderCreatePage.receiverInformation.selleraddresscomplementPlaceholder"
          ),
          key: "seller_address_complement",
          helpText: this.$t(
            "orderCreatePage.receiverInformation.selleraddresscomplementHelpText"
          ),
        },
        {
          required: true,
          id: "seller_city",
          name: this.$t("sellerCity"),
          classes: "mb-3 w-32-5",
          placeholder: "",
          key: "seller_city",
        },
        {
          required: true,
          id: "seller_state",
          name: this.$t("sellerState"),
          classes: "mb-3 w-32-5",
          placeholder: "",
          key: "seller_state",
        },
        {
          required: true,
          id: "seller_website",
          name: this.$t("sellerWebsite"),
          classes: "mb-3 w-66",
          placeholder: this.$t(
            "orderCreatePage.receiverInformation.sellerwebsitePlaceholder"
          ),
          key: "seller_website",
        },
        {
          required: true,
          id: "seller_logo_url",
          name: this.$t("sellerLogoUrl"),
          classes: "mb-3 w-66",
          placeholder: this.$t(
            "orderCreatePage.receiverInformation.sellerLogoUrlPlaceholder"
          ),
          key: "seller_logo_url",
        },
        {
          required: false,
          id: "seller_logo_url_img",
          type: "image",
          classes: "w-32-5 logo-previsualization",
          height: 83,
          src: this.receiver.seller_logo_url,
        },
      ];
    },
    statesOptions() {
      const finalStates = STATES[`${this.receiver.customer_country}_STATES`];

      return Object.values(finalStates).map((state) => ({
        text: Object.keys(finalStates).find(
          (key) => finalStates[key] === state
        ),
        value: state,
      }));
    },
  },
  watch: {
    "receiver.customer_country": function (newValue) {
      const countryAttributes = COUNTRIES_RECEIVER_REGEX.find(
        ({ iso }) => iso === newValue
      );

      this.receiverCountryConfigurations.countryName =
        countryAttributes.countryName;
      this.receiverCountryConfigurations.countryAlpha2Code = newValue;
      this.receiverCountryConfigurations.documentName =
        countryAttributes.documentName;
      this.receiverCountryConfigurations.documentNameHelpText =
        countryAttributes.documentNameHelpText;
      this.receiverCountryConfigurations.documentFormat =
        countryAttributes.documentFormat;
      this.receiverCountryConfigurations.mask = countryAttributes.mask
        .trim()
        .split(",");
      this.receiverCountryConfigurations.cepFormat =
        countryAttributes.cepFormat;
      this.receiverCountryConfigurations.cepLength = countryAttributes
        ? countryAttributes.maskLength
        : "0";
      this.receiverCountryConfigurations.documentMasks =
        countryAttributes.documentMasks || null;
    },
    "receiver.seller_country": function (newValue) {
      const countryAttributes = COUNTRIES_REGEX.find(
        ({ iso }) => iso === newValue
      );

      /* dinamic phone lang based on seller country
      const mask = this.countriesPhoneMask.find(
        ({ iso }) => iso === newValue
      ).mask;
      let finalLength = "";

      if (Array.isArray(mask)) {
        finalLength = mask[0].replace(/[^#]/g, "").length;
      } else {
        finalLength = mask.replace(/[^#]/g, "").length;
      }
      */
      this.sellerCountryConfigurations = {
        /* phoneLength: 6, */
        cepLength: countryAttributes ? countryAttributes.maskLength : "0",
      };
    },
    "receiver.customer_document_number": function (newValue) {
      const hasValidDocumentName = getValidDocumentName(newValue);
      if (hasValidDocumentName) {
        this.receiver.customer_document_type = hasValidDocumentName;
        this.$emit("updateValidFields", "customer_document_type");
      }
    },
    /*
    receiver: {
      //deep: true,
      handler() {
        const hasValidDocumentName = getValidDocumentName(this.receiver.customer_document_number)
        if (hasValidDocumentName) {
          this.receiver.customer_document_type = hasValidDocumentName
        }
        this.$emit('input', this.receiver);
        this.$emit('receiverUpdated');
      },
    },
    */
  },
};
</script>

<style></style>
