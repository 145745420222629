<template>
  <div>
    <div class="d-flex justify-content-between mt-4">
      <div></div>
      <NbButton
        :disabled="checkAllStepsComplete"
        variant="primary"
        data-toggle="modal"
        data-target="#overviewModal"
        @click="loadQuotationDecomposition()"
      >
        {{ $t("orderCreatePage.overview") }} >
      </NbButton>
    </div>
    <NbPageFooter
      :text="$t('orderCreatePage.footer.howCreateOrder')"
      link="#"
    />
    <!-- Modals -->
    <NbModal
      id="overviewModal"
      modalConfig="modal-dialog-centered"
      width="700px"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("orderCreatePage.courierInformation.costSummary") }}
        </div>
      </template>
      <template v-slot:body>
        <NbTablev2
          v-if="quoteDecomposited"
          tableOf="quoteDecomposited"
          height="100%"
          :hiddeTableOptions="true"
          :allFields="allFieldscostSummaryBox"
          :fields="fieldscostSummaryBox"
          :hasParentItens="true"
          :startItems="quoteDecomposited.volumes || []"
          @reloadFields="fieldscostSummaryBox = $event"
        >
          <template #cell(volume)="data">
            <div>
              {{
                Number(data.item.index) + 1 === quoteDecomposited.volumes.length
                  ? "TOTAL"
                  : Number(data.item.index) + 1
              }}
            </div>
          </template>
          <template #cell(products_value)="data">
            <div>
              {{
                standardizeValue(
                  data.item.products_value,
                  simplifyCurrency(order.shipment.currency)
                )
              }}
            </div>
          </template>
          <template #cell(freight_value)="data">
            <div>
              {{
                standardizeValue(
                  data.item.freight_value,
                  simplifyCurrency(order.shipment.currency)
                )
              }}
            </div>
          </template>
          <template #cell(tax_value)="data">
            <div>
              {{
                standardizeValue(
                  data.item.tax_value,
                  simplifyCurrency(order.shipment.currency)
                )
              }}
            </div>
          </template>
          <template #cell(total_value)="data">
            <div>
              {{
                standardizeValue(
                  data.item.total_value,
                  simplifyCurrency(order.shipment.currency)
                )
              }}
            </div>
          </template>
          <template #cell(details)="data">
            <div
              v-if="
                Number(data.item.index) + 1 !== quoteDecomposited.volumes.length
              "
            >
              <NbButton
                variant="tertiary"
                icon="fas fa-file-invoice fa-2x"
                size="lg"
                data-toggle="modal"
                data-target="#volume-detail-modal"
                @click="showVolumeDetail(data.item)"
              />
            </div>
          </template>
        </NbTablev2>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbCheckbox
            id="checkbox-example-2"
            :name="$t('loginPage.iAgree') + ' *'"
            :error="[
              agreement
                ? ''
                : $t('orderCreatePage.courierInformation.agreeSummary'),
            ]"
            v-model="agreement"
          />
          <NbButton
            :disabled="agreement ? false : true"
            @click="overviewModal()"
          >
            {{ $t("confirm") }}
          </NbButton>
        </div>
      </template>
    </NbModal>

    <NbModal
      id="volume-detail-modal"
      modalConfig="modal-dialog-centered"
      width="1100px"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("items") }}
        </div>
      </template>
      <template v-slot:body>
        <NbTablev2
          height="100%"
          :hiddeTableOptions="true"
          :hasParentItens="true"
          tableOf="boxesPage"
          :allFields="allFieldscostVolumeDetail"
          :fields="fieldscostVolumeDetail"
          :startItems="volumeDetail.items || []"
          @reloadFields="fieldscostVolumeDetail = $event"
        >
          <template #cell(name)="data">
            {{ data.item.name || "-" }}
          </template>
          <template #cell(description)="data">
            {{ displayOnlyCharacters(data.item.description, 25) || "-" }}
          </template>
          <template #cell(unit_products_value)="data">
            {{
              standardizeValue(
                data.item.unit_products_value,
                simplifyCurrency(order.shipment.currency)
              )
            }}
          </template>
          <template #cell(unit_freight_value)="data">
            {{
              standardizeValue(
                data.item.unit_freight_value,
                simplifyCurrency(order.shipment.currency)
              )
            }}
          </template>
          <template #cell(unit_tax_value)="data">
            {{
              standardizeValue(
                data.item.unit_tax_value,
                simplifyCurrency(order.shipment.currency)
              )
            }}
          </template>
          <template #cell(unit_total_value)="data">
            {{
              standardizeValue(
                data.item.unit_total_value,
                simplifyCurrency(order.shipment.currency)
              )
            }}
          </template>
          <template #cell(quantity)="data">
            {{ data.item.quantity }}
          </template>
          <template #cell(total_value)="data">
            {{
              standardizeValue(
                data.item.total_value,
                simplifyCurrency(order.shipment.currency)
              )
            }}
          </template>
        </NbTablev2>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <div></div>
          <NbButton data-dismiss="modal" aria-label="Close">
            {{ $t("confirm") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <!-- end - Modals -->
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbCheckbox from "@/components/buttons/NbCheckbox.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbPageFooter from "@/components/pagescomponents/NbPageFooter.vue";

//services
import QuoteService from "@/services/QuoteService";
const quoteService = new QuoteService();

export default {
  name: "OrdersFooter",
  components: {
    NbButton,
    NbModal,
    NbCheckbox,
    NbTablev2,
    NbPageFooter,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    allSteps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      agreement: false,
      quoteDecomposited: {
        volumes: [],
      },
      fieldscostSummaryBox: [],
      fieldscostVolumeDetail: [],
      volumeDetail: { items: [] },
    };
  },
  methods: {
    getFreightCalcs() {
      const finalData = { freight_cost: 0, freight_value_details: [] };

      this.order.shipment.volumes_attributes.forEach((volume) => {
        finalData.freight_cost += Number(volume.freight_cost);
        finalData.freight_value_details.push(Number(volume.freight_value));
      });
      return finalData;
    },
    buildDataToSend() {
      const dataToSend = {};
      const freightCalcs = this.getFreightCalcs();

      dataToSend.customer_postal_code =
        this.order.receiver.customer_postal_code;
      dataToSend.customer_state = this.order.receiver.customer_state;
      dataToSend.incoterm = this.order.shipment.incoterm;
      dataToSend.contract_id = this.order.courier.contract_id;
      dataToSend.freight_cost = freightCalcs.freight_cost;
      dataToSend.freight_value_details = freightCalcs.freight_value_details;
      dataToSend.is_landed_cost = this.order.shipment.is_landed_cost;
      dataToSend.currency = this.order.shipment.currency;
      dataToSend.volumes_attributes = this.order.shipment.volumes_attributes;

      return dataToSend;
    },
    loadQuotationDecomposition() {
      this.resetAgreement();
      const dataToSend = this.buildDataToSend();
      quoteService
        .getQuotationDecomposition(dataToSend)
        .then((response) => {
          let lastVolume = {};

          this.quoteDecomposited = response.data.data;

          lastVolume.products_value = response.data.data.products_value;
          lastVolume.freight_value = response.data.data.freight_value;
          lastVolume.tax_value = response.data.data.tax_value;
          lastVolume.total_value = response.data.data.total_value;
          lastVolume.items = [];

          if (
            this.selectedContract &&
            this.selectedContract.is_auto_tax_defined
          ) {
            let allTaxes = 0;
            this.quoteDecomposited.volumes.forEach((volume, index) => {
              let taxes = 0;
              if (
                this.order.shipment.volumes_attributes[index] &&
                this.order.shipment.volumes_attributes[index]
                  .duties_tax_value &&
                this.order.shipment.volumes_attributes[index].sales_tax_value
              ) {
                taxes =
                  parseFloat(
                    this.order.shipment.volumes_attributes[index]
                      .duties_tax_value
                  ) +
                  parseFloat(
                    this.order.shipment.volumes_attributes[index]
                      .sales_tax_value
                  );
              }
              volume.tax_value = parseFloat(taxes).toFixed(2);
              allTaxes = allTaxes + taxes;
            });

            lastVolume.tax_value = parseFloat(allTaxes).toFixed(2);
          }

          this.quoteDecomposited.volumes.push(lastVolume);

          this.$emit("setQuoteDecomposited", this.quoteDecomposited);
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger", 3000);
          });
        });
    },
    overviewModal() {
      this.$emit("checkContractDocuments");
      this.$helpers.closeModal("overviewModal");
    },
    standardizeValue(val, currency) {
      if (typeof val === "number" || typeof val === "string") {
        return currency + parseFloat(val).toFixed(2);
      }
      return "-";
    },
    simplifyCurrency(currency) {
      if (currency === "USD") {
        return "$";
      } else {
        return "R$";
      }
    },
    showVolumeDetail(volume) {
      this.volumeDetail = {};
      this.volumeDetail = volume;
    },
    displayOnlyCharacters(word, maxChars) {
      if (word.length > maxChars) {
        return word.substring(0, maxChars - 5) + "(...)";
      }
      return word;
    },
    resetAgreement() {
      this.agreement = false;
    },
  },
  computed: {
    allFieldscostSummaryBox() {
      return [
        { key: "volume", label: this.$t("box") },
        { key: "products_value", label: this.$t("productsValue") },
        { key: "freight_value", label: this.$t("freightValue") },
        { key: "tax_value", label: this.$t("taxValue") },
        { key: "total_value", label: this.$t("totalValue") },
        { key: "details", label: this.$t("details") },
      ];
    },
    allFieldscostVolumeDetail() {
      return [
        { key: "name", label: this.$t("name") },
        { key: "description", label: this.$t("description") },
        { key: "unit_products_value", label: "Products Value" },
        { key: "unit_freight_value", label: this.$t("freightValue") },
        { key: "unit_tax_value", label: this.$t("taxValue") },
        { key: "unit_total_value", label: this.$t("totalValue") },
        { key: "quantity", label: this.$t("quantity") },
        { key: "total_value", label: this.$t("totalValue") },
      ];
    },
    checkAllStepsComplete() {
      const finalResult = this.allSteps.every((step) => step.formDone === true);

      return !finalResult;
    },
  },
};
</script>

<style></style>
